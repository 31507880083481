// Custom.scss
// Option A: Include all of Bootstrap

$secondary: #5c4fa2;
$primary: #1a1149;
$success: #4ebb4e;
$danger: #ea6161;
$info: #1a1149;
$muted: #1a1149;

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  opacity: 1;
}

.text-link {
  // color: inherit;
  // text-decoration: none;
  margin: 10px;
  padding: 5px;
  border: 1px solid $success;
  border-radius: 5px;
}

.text-link:hover {
  background-color: $success;
  color: white;
  border-radius: 5px;
}

// Include any default variable overrides here (though functions won't be available)

@import "../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
