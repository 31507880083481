.navbar-clearance {
  height: 80px;
  filter: contrast(150%) brightness(100%);
  background: radial-gradient(
      circle at 0% 0%,
      rgba(12, 6, 68, 0.9),
      rgba(13, 0, 134, 0.7),
      rgba(112, 34, 184, 0.4),
      rgba(226, 120, 59, 0.3)
    ),
    url(noise.svg);
  margin-bottom: 100px;
}
