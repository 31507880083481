html {
  font-family: sans-serif;
}

body {
  background-image: url("../assets/memphis-mini.png");
  height: 100%;
}

* {
  box-sizing: border-box;
}

li {
  list-style-type: inherit;
}

.blurry {
  filter: blur(5px);
  position: absolute;
  /* background-image: url(../assets/bg.jpg); */
  background-size: cover;
  background-position: center;
  width: 100vw;
  min-height: 400px;
  height: 60vh;
  z-index: -1;
  left: -5px;
  top: -5px;
  transform: scale(1.1);
}

.background-blurry {
  overflow: hidden;
  z-index: 1;
  position: relative 0 0;
  width: 100vw;
  min-height: 400px;
  height: 60vh;
}

.hero-background {
  /* position: absolute 0 0; */
  width: 100vw;
  min-height: 400px;
  height: 50vh;

  filter: contrast(150%) brightness(100%);
  background: radial-gradient(
      circle at 0% 0%,
      #0c0644e6,
      #0d0086b3,
      #7022b866,
      #e2783b4d
    ),
    url(noise.svg);
}

.hero {
  height: 80%;
}

.title {
  font-size: 6em;
  font-style: italic;
  color: white;
  /* font-family: "Abril Fatface", sans-serif; */
}

.butter {
  color: rgb(255, 241, 159) !important;
}

.white {
  color: white;
}

.content {
  margin-top: 100px;
  height: 300px;
}

.big-icon {
  font-size: 4em;
  padding: 0;
}

h2 {
  margin: 0;
}

.headertext {
  font-size: 1.2em;
  font-weight: 100;
}

.footer {
  height: 200px;
}

.highlight:hover {
  background-color: rgb(231, 231, 231);
  transition: all 200ms ease-out;
}

.highlight {
  transition: all 200ms ease-out;
}

.transition {
  transition: all 200ms ease-out;
}

.custom-toast {
  background-color: white;
}

.z-super {
  z-index: 10000;
}

.fader {
  transition: all 2ms;
}

input:invalid {
  outline: 2px solid rgb(223, 148, 148);
  outline-offset: 3px;
}

.super-z {
  z-index: 3000;
}
